import { h } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import MutationSummary from 'mutation-summary'
import ResizeSensor from 'css-element-queries/src/ResizeSensor'
import deepmerge from 'deepmerge'

import _styles from './styles'

import formatAmount from '../../utils/formatAmount'
import { isAdmin } from '../round-up/App'

// Tell transpiler to transform JSX into h() calls:
/** @jsx h */

const actions = [ '/cart', '/checkout' ]
const findVariantInForms = variant =>
  document.querySelectorAll(actions.map(action =>
    `form[action="${action}"] a[href$="variant=${variant.id}"]`
  ).join())

const thumb = (src, width) =>
  src?.replace(/(\.[a-z0-9]+(\?.+)?$)/i, `_${width}x${width}@2x$1`)

const Donation = ({ product, cart, moneyFormat, matchUpTo, preview }) => {
  const variant = product.variants[0]
  const isRoundUp = variant.price == '0.01'
  const donationBlock = useRef()
  const [ width, setWidth ] = useState(null)

  const cartItem = cart && cart.items.find(item => item.product_id == product.id)
  const isInCart = cart ? cartItem : findVariantInForms(variant).length

  // Bail early if donation is in cart or is round-up
  const showAdminMessage = isRoundUp && isAdmin()
  const shouldBail = !showAdminMessage && (isInCart || isRoundUp)

  const injectMatchData = () => {
    const selector = actions.map(action => `form[action="${action}"]`).join()

    const injectInput = form =>
      parseFloat(matchUpTo) && form.insertAdjacentHTML('beforeEnd',
        `<input type="hidden" name="attributes[Match][${product.id}]"
          value="${matchUpTo}">`)

    document.querySelectorAll(selector).forEach(injectInput)
    new MutationSummary({
      callback (summaries) {
        summaries[0].added.forEach(injectInput)
      },
      queries: [{ element: selector }]
    })
  }

  useEffect(() => {
    if (isInCart) injectMatchData()
    if (shouldBail) return

    const handleResize = () => setWidth(donationBlock.current.offsetWidth)
    const resizeSensor = new ResizeSensor(donationBlock.current, handleResize)
    handleResize()
    return () => resizeSensor.detatch(donationBlock.current, handleResize)
  }, [])

  if (shouldBail) return

  const addDonation = () => {
    if (preview) return

    const container = document.createElement('div')
    container.innerHTML = `
      <form method="post" action="/cart/add">
        <input type="hidden" name="quantity" value="1">
        <input type="hidden" name="id" value="${variant.id}">
      </form>`
    document.body.appendChild(container)
    container.children[0].submit()
  }

  const styles = width >= 500
  ? deepmerge(_styles.base, _styles.large)
  : deepmerge(_styles.base, _styles.small)

  if (showAdminMessage) return (
    <div className="plg-add--donationBlock" style={styles.donationBlock} ref={donationBlock}>
      Please update your embed code to use your new donation button.
      This message is only visible to you.
    </div>
  )

  return (
    <div className="plg-add--donationBlock" style={styles.donationBlock} ref={donationBlock}>
      { product.image ?
        <div className="plg-add--itemImageColumn" style={styles.itemImageColumn}>
          <div className="plg-add--itemImageWrapper" style={styles.itemImageWrapper}>
            <img className="plg-add--itemImage" style={styles.itemImage}
              src={thumb(product.image.src, 95)}
              alt={product.title}
            />
          </div>
        </div>
        : null
      }

      <div className="plg-add--itemTitleColumn" style={styles.itemTitleColumn}>
        { product.title ?
          <div className="plg-add--itemTitle" style={styles.itemTitle}>
            {product.title}
          </div>
          : null
        }

        { product.body_html || matchUpTo ?
          <div className="plg-add--itemDescription" style={styles.itemDescription}>
            { product.body_html ?
              <div className="plg-add--itemDescriptionHTML"
                dangerouslySetInnerHTML={{__html: product.body_html}} />
              : null
            }
            { matchUpTo ?
              <p className="plg-add--matchUpTo" style={styles.matchUpTo}>
                We will match your donation up to {formatAmount(matchUpTo, moneyFormat)}
              </p>
              : null
            }
          </div>
          : null
        }

        <button type="button" className="btn plg-add--btn" style={styles.btn}
          onClick={addDonation}>
          { isInCart
            ? `Update round-up to ${formatAmount(variant.price, moneyFormat)}`
            : `Add ${formatAmount(variant.price, moneyFormat)} donation`
          }
        </button>
      </div>
    </div>
  )
}

export default Donation
